import merge from '~lodash.mergewith'
import * as CoreSdk from '~@sentry/core'
import { captureUserFeedback, forceLoad, onLoad, showReportDialog, wrap } from '~@sentry/browser'
import { extraErrorDataIntegration, reportingObserverIntegration } from '~@sentry/integrations'
import { init } from '~@sentry/vue'

export { init }
export const SentrySdk = { ...CoreSdk, ...{ captureUserFeedback, forceLoad, onLoad, showReportDialog, wrap } }

/** @type {string[]} */
const DISABLED_INTEGRATION_KEYS = []

/**
 * @typedef {Parameters<typeof init>[0]} InitConfig
 * @param {import('@nuxt/types').Context} ctx
 * @return {Promise<InitConfig>}
 */
export function getConfig (ctx) {
  /** @type {InitConfig} */
  const config = {
    dsn:"https:\u002F\u002F8ef8dd7840d288c63354174847653f79@o4507740805857280.ingest.us.sentry.io\u002F4507740843343872",
    environment:"production",
  }

  /** @type {NonNullable<InitConfig>['integrations']} */
  const resolvedIntegrations = [
    extraErrorDataIntegration(),
    reportingObserverIntegration({ types:["crash"] }),
  ]

  config.integrations = (defaultIntegrations) => {
    return [
      ...defaultIntegrations.filter(integration => !DISABLED_INTEGRATION_KEYS.includes(integration.name)),
      ...resolvedIntegrations,
    ]
  }
  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  return config
}
